import './App.css';
import Footer from './component/footer/Footer';
import './component/footer/Footer.css';
import HomePage from './component/homepage/HomePage';
import { useEffect, useState } from "react";
import Products from './component/products/Products';
import Company from './component/company/Company';
import Navigation from './pages/Navigation';
import { Route,Routes } from 'react-router-dom';
import ComingSoon from './component/coming-soon/ComingSoon';
import Services from './component/services/services/Services';
import Service from './component/services/services/Service';

import engJson from './component/services/services/resources/Engineering.json'
import pmJson from './component/services/services/resources/PM.json'
import dsJson from './component/services/services/resources/DS.json'
import admJson from './component/services/services/resources/ADM.json'
import marketingJson from './component/services/services/resources/Marketing.json'
import itJson from './component/services/services/resources/IT.json'
import Consulting from './component/consulting/Consulting';

import IdeaJson from './component/commons/jsons/Idea.json';
import OperationsJson from './component/commons/jsons/Operations.json';
import SustainabilityJson from './component/commons/jsons/Sustainability.json';
import TransformationJson from './component/commons/jsons/Transformation.json';


import { ReactComponent as EngineeringIcon} from '../src/component/services/services/resources/eng_icon.svg';
import { ReactComponent as DataScienceIcon} from '../src/component/services/services/resources/data_sc_icon.svg';
import { ReactComponent as ProjectManagmentIcon} from '../src/component/services/services/resources/project_man_icon.svg';
import { ReactComponent as ADMIcon} from '../src/component/services/services/resources/adm_icon.svg';
import { ReactComponent as MarketingIcon} from '../src/component/services/services/resources/marketing_icon.svg';
import { ReactComponent as SettingIcon} from '../src/component/services/services/resources/clarity_settings-outline-badged.svg';

import { ReactComponent as IdeaIcon} from '../src/component/consulting/resources/carbon_idea.svg';
import { ReactComponent as SustainabilityIcon} from '../src/component/consulting/resources/streamline_target.svg';
import { ReactComponent as TransformationIcon} from '../src/component/consulting/resources/ri_shake-hands-fill.svg';
import { ReactComponent as OperationsIcon} from '../src/component/consulting/resources/ri_todo-line.svg';

import './component/services/services/resources/Service.css'
import ScrollToTop from './component/commons/ScrollToTop';




function App() {

  // const [loading, setLoading] = useState(true)
  //   useEffect(() => {
  //       setTimeout(() => setLoading(false), 2000)
  //   }, [])
  //   if (loading) {
  //       return <div className='App-background'></div>
  //   }
  

  return (
    <div className="App">
      <Navigation/>
      <div>
        <Routes>
          <Route path='/' element={<HomePage/>}/>
          <Route path='/products' element={<Products/>}/>
          <Route path='/services' element={<Services/>}/>
          <Route path='/consulting' element={<Consulting/>}/>
          <Route path='/company' element={<Company/>}/>
          <Route path='/services/engineering' element={<Service service={engJson} ServiceIcon={<EngineeringIcon className='service-icon-box'></EngineeringIcon>}/>}/>
          <Route path='/services/Data Science' element={<Service service={dsJson} ServiceIcon={<DataScienceIcon className='service-icon-box'></DataScienceIcon>}/>}/>
          <Route path='/services/Project Management' element={<Service service={pmJson} ServiceIcon={<ProjectManagmentIcon className='service-icon-box'></ProjectManagmentIcon>}/>}/>
          <Route path='/services/Application Development & Management' element={<Service service={admJson} ServiceIcon={<ADMIcon className='service-icon-box'></ADMIcon>}/>}/>
          <Route path='/services/Marketing' element={<Service service={marketingJson} ServiceIcon={<MarketingIcon className='service-icon-box'></MarketingIcon>}/>}/>
          <Route path='/services/IT' element={<Service service={itJson} ServiceIcon={<SettingIcon className='service-icon-box'></SettingIcon>}/>}/>
          <Route path='/consulting/Idea' element={<Service service={IdeaJson} ServiceIcon={<IdeaIcon className='service-icon-box'></IdeaIcon>}/>}/>
          <Route path='/consulting/Sustainability' element={<Service service={SustainabilityJson} ServiceIcon={<SustainabilityIcon className='service-icon-box'></SustainabilityIcon>}/>}/>
          <Route path='/consulting/Operations' element={<Service service={OperationsJson} ServiceIcon={<OperationsIcon className='service-icon-box'></OperationsIcon>}/>}/>
          <Route path='/consulting/Transformation' element={<Service service={TransformationJson} ServiceIcon={<TransformationIcon className='service-icon-box'></TransformationIcon>}/>}/>
        </Routes>
      </div>
      {/* <Company/>
      <Products></Products>
      <HomePage/> */}
      <Footer/>
      <ScrollToTop />
    </div>
  );
}

export default App;
